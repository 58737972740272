import { template as template_c93a5c577a3a47e4bae0a7056bbb60db } from "@ember/template-compiler";
const FKControlMenuContainer = template_c93a5c577a3a47e4bae0a7056bbb60db(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
