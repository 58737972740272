import { template as template_f1a42da54e2e44d4830e92ff71c3a962 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f1a42da54e2e44d4830e92ff71c3a962(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
