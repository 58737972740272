import { template as template_322564dd3b104e20b189b159320e6dec } from "@ember/template-compiler";
const FKLabel = template_322564dd3b104e20b189b159320e6dec(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
